/**
 * @summary DevPIO React Application
 * @module Main
 * @description Landing page
 */
import React from "react";
import { ReactComponent as Panda } from "../resources/images/panda-logo.svg";
import styles from "./main.module.scss";

const Main = () => {
  return (
    <div className={styles.container}>
      <Panda width={90} height={90} className={styles.logo} fill="#1f3d8e" />
      <Panda width={90} height={90} className={styles.logo} fill="#14285e" />
      <Panda width={90} height={90} className={styles.logo} />
      <h1 className={styles.header}>DevPIO</h1>
      <p className={styles.content}>
        "I must not fear. Fear is the mind-killer. Fear is the little-death that
        brings total obliteration. I will face my fear. I will permit it to pass
        over me and through me. And when it has gone past I will turn the inner
        eye to see its path. Where the fear has gone there will be nothing. Only
        I will remain."
      </p>
    </div>
  );
};

export default Main;
